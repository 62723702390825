import React, { useCallback, useState, useRef } from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';

import {
  AppBar,
  Toolbar,
  MenuItem,
  Menu,
  CssBaseline,
  IconButton,
  useTheme,
  Divider,
  Avatar,
} from '@material-ui/core';

import { Switch, useRouteMatch } from 'react-router-dom';

import { useAuth } from '../../hooks/Auth';
import Logo from '../../assets/Ceapia-02.png';
import Route from '../../routes/Route';

import Input from '../../components/Input';
import Alert from '../../components/Alert';
import DialogForm from '../../components/DialogForm';

import { UpdateAdminData } from './dtos';
import { Container, Header, useStyles } from './styles';

import api from '../../services/api';
import Registration from '../AnnualRegistation';
import Preceptorships from '../Preceptorships';

import { AlertProps } from '../../utils/dtos';
import getValidationErrors from '../../utils/getValidationErrors';

const Dashboard: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const formRefUpdate = useRef<FormHandles>(null);
  const [myDataOpen, setMyDataOpen] = useState(false);
  // const [admin, setAdmin] = useState<Admin>({} as Admin);
  const [alert, setAlert] = useState<AlertProps>({
    isActive: false,
  });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const { logout, user, token } = useAuth();
  const { path } = useRouteMatch();

  const Authorization = `Bearer ${token}`;

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAlert = useCallback(() => {
    setAlert({ isActive: false });
  }, []);

  const handleMyDataOpen = useCallback(async () => {
    try {
      const response = await api.get(`/admins/me`, {
        headers: {
          Authorization,
        },
      });

      // setAdmin(response.data);
      setMyDataOpen(true);
    } catch (error) {
      setAlert({
        isActive: true,
        type: 'error',
        message: 'Erro ao buscar dados do perfil.',
      });
    } finally {
      handleClose();
    }
  }, []);

  const handleMyDataClose = () => {
    setMyDataOpen(false);
  };

  const handleSubmit = useCallback(async (data: UpdateAdminData) => {
    try {
      formRefUpdate.current?.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        email: Yup.string()
          .email('Digite um e-mail válido.')
          .required('E-mail obrigatório.'),
        password: Yup.string(),
        passwordConfirm: Yup.string().oneOf(
          [Yup.ref('password')],
          'Senhas devem ser iguais.',
        ),
      });

      await schema.validate(data, { abortEarly: false });

      const { password } = data;

      if (!password) {
        delete data.password;
        delete data.passwordConfirm;
      }

      await api.put(`/admins`, data, {
        headers: {
          Authorization,
        },
      });

      setAlert({
        isActive: true,
        type: 'success',
        message: 'Perfil atualizado com sucesso.',
      });

      handleMyDataClose();
      window.location.reload(false);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);

        formRefUpdate.current?.setErrors(errors);
      } else {
        setAlert({
          isActive: true,
          type: 'error',
          message: 'Erro ao atualizar perfil.',
        });
      }
    }
  }, []);

  return (
    <Container>
      <DialogForm
        open={myDataOpen}
        handleClose={handleMyDataClose}
        handleSubmit={handleSubmit}
        title="Editar Perfil"
        formRef={formRefUpdate}
        // initialData={admin}
      >
        <Input
          name="name"
          type="text"
          label="Nome"
          placeholder="Digite um nome"
        />

        <Input
          name="email"
          type="email"
          label="E-mail"
          placeholder="Digite um e-mail"
        />

        <Input
          name="password"
          type="password"
          label="Nova Senha"
          placeholder="Digite uma nova senha (opcional)"
        />

        <Input
          name="passwordConfirm"
          type="password"
          label="Confirmar Senha"
          placeholder="Confirme a nova senha (opcional)"
        />
      </DialogForm>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar className={classes.toolbar}>
            <div className={classes.toolbar}>
              <img
                src={Logo}
                alt="logo"
                style={{
                  margin: 0,
                  padding: 0,
                  background: '#fff',
                  width: 'fit-content',
                }}
              />
            </div>

            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="default"
              >
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ marginRight: '15px' }}
                />

                <Avatar
                  style={{
                    background: '#4472CA',
                    padding: 30,
                    opacity: 0.9,
                    boxShadow: '1px 1px 2px #111',
                  }}
                >
                  {`${user.name.split(' ')[0][0]}${user.name.split(' ')[1][0]}`}
                </Avatar>
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                keepMounted
                open={openMenu}
                onClose={handleClose}
                className={classes.menuAccount}
              >
                <MenuItem onClick={handleMyDataOpen}>Meus dados</MenuItem>
                <MenuItem onClick={logout}>Sair</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>

        <main className={classes.content}>
          <div className={classes.toolbar} />

          <Header>
            <div>
              <h2>{`Bem-vindo, ${user.name}`}</h2>
              <span>{user.email}</span>
            </div>
          </Header>

          <Divider style={{ margin: '2%' }} />

          <Switch>
            <Route exact path={`${path}`} component={Registration} isPrivate />
            <Route
              path={`${path}/registration/:id/student/:groupId`}
              component={Preceptorships}
              isPrivate
            />
          </Switch>
        </main>
      </div>
      );
      {alert.isActive && (
        <Alert onClose={handleAlert} type={alert.type || 'success'}>
          {alert.message}
        </Alert>
      )}
    </Container>
  );
};

export default Dashboard;
