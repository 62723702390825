import React from 'react';
import { FormHandles } from '@unform/core';

import { Save } from '@material-ui/icons';

import { Button, DialogTitle, DialogContent, Dialog } from '@material-ui/core';

import { Container, FormContainer, FormActions, useStyles } from './styles';

interface DialogFormProps {
  open: boolean;
  handleClose(): void;
  handleSubmit(data: any): void;
  formRef: React.RefObject<FormHandles>;
  title: string;
  initialData?: any;
}

const DialogForm: React.FC<DialogFormProps> = ({
  children,
  open,
  handleClose,
  handleSubmit,
  formRef,
  title,
  initialData,
}) => {
  const classes = useStyles();

  return (
    <Container>
      <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent className={classes.paper}>
          <FormContainer
            ref={formRef}
            initialData={initialData}
            onSubmit={handleSubmit}
          >
            <div className={classes.content}>{children}</div>

            <FormActions className={classes.actions}>
              <Button type="submit" color="primary" variant="contained">
                <Save />
                Salvar
              </Button>
              <Button
                onClick={handleClose}
                type="button"
                color="inherit"
                variant="contained"
              >
                Cancelar
              </Button>
            </FormActions>
          </FormContainer>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default DialogForm;
