import styled from 'styled-components';

export const Container = styled.div`
  margin: 1%;
  width: 100%;

  * {
    width: 100%;
  }
`;
