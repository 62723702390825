import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import moment from 'moment';
import { closestTo, isAfter, isBefore, isEqual } from 'date-fns';

import {
  Button,
  Typography,
  Tooltip,
  Avatar,
  CardActions,
  CardContent,
  CardHeader,
  Card,
  Chip,
  IconButton,
  Breadcrumbs,
  Link,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';

import { Container, useStyles } from './styles';

import api from '../../services/api';
import Alert from '../../components/Alert';
import { useAuth } from '../../hooks/Auth';

import { AnnualRegistration, Schedule } from './dtos';

import { AlertProps } from '../../utils/dtos';

const Admins: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { path } = useRouteMatch();
  const { token, user } = useAuth();
  const [studentGroup, setStudentGroup] = useState('');

  const [canSeePreceptorships, setCanSeePreceptorships] = useState<boolean>(
    true,
  );

  const [registration, setRegistration] = useState<AnnualRegistration | null>(
    null,
  );

  const [alert, setAlert] = useState<AlertProps>({
    isActive: false,
  });

  const [loading, setLoading] = useState(true);

  const Authorization = `Bearer ${token}`;

  useEffect(() => {
    async function getRegistration() {
      try {
        const registrationResponse = await api.get(
          '/students/annualRegistration',
          {
            headers: {
              Authorization,
            },
          },
        );

        setRegistration(registrationResponse.data);

        const userAvailableSchedules: Date[] = registrationResponse.data.schedules
          .filter((schedule: Schedule) => user.groups.includes(schedule.group))
          .map((schedule: Schedule) => new Date(schedule.startTime));

        const now = new Date();

        const nextSchedule = closestTo(now, userAvailableSchedules);

        const selectedSchedule: Schedule = registrationResponse.data.schedules.find(
          (schedule: Schedule) =>
            isEqual(new Date(schedule.startTime), nextSchedule),
        );

        setStudentGroup(selectedSchedule.group);

        const inSchedule = isAfter(now, nextSchedule);

        const finishedAllRounds = registrationResponse.data.schedules.every(
          (schedule: Schedule) => isBefore(new Date(schedule.endTime), now),
        );

        if (
          !userAvailableSchedules.length ||
          !inSchedule ||
          (isBefore(new Date(selectedSchedule.endTime), now) &&
            !finishedAllRounds)
        )
          setCanSeePreceptorships(false);

        setAlert({
          isActive: true,
          type: 'success',
          message: 'Inscrição anual encontrada com sucesso.',
        });
      } catch (error) {
        if (error.message.includes('401')) {
          setAlert({
            isActive: true,
            type: 'warning',
            message: 'Sessão expirada, faça o login novamente.',
          });
        } else {
          setAlert({
            isActive: true,
            type: 'error',
            message: 'Erro ao buscar inscrição anual.',
          });
        }
      } finally {
        setLoading(false);
      }
    }

    getRegistration();
  }, []);

  const handleAlert = useCallback(() => {
    setAlert({ isActive: false });
  }, []);

  return (
    <Container>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/">
          Dashboard
        </Link>
        <Typography color="textPrimary">Incrições anuais</Typography>
      </Breadcrumbs>

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className="registration-card">
        {!loading && !registration && (
          <Card>
            <CardContent>
              <Typography color="textPrimary">
                Não existem inscrições ativas este ano.
              </Typography>
            </CardContent>
          </Card>
        )}

        {registration && (
          <Card key={registration._id} className={classes.root}>
            <CardHeader
              avatar={
                <Avatar className={classes.avatar}>{registration.year}</Avatar>
              }
              title={registration.name}
              subheader={`Inscrições abertas em ${moment(
                registration.createdAt,
              ).format('DD/MM/YYYY')}`}
            />

            <CardContent className="card-content">
              <Typography color="textPrimary" component="p" align="center">
                Seus horários de inscrição:
              </Typography>

              <Typography
                color="textPrimary"
                component="p"
                align="center"
                className="schedules"
              >
                {registration.schedules
                  .filter((schedule) => user.groups.includes(schedule.group))
                  .map((studentSchedule) => (
                    <Chip
                      label={`${moment(studentSchedule.startTime).format(
                        'DD/MM',
                      )} das ${moment(studentSchedule.startTime).format(
                        'HH:mm',
                      )} até ${moment(studentSchedule.endTime).format(
                        'HH:mm',
                      )}`}
                      variant="outlined"
                      color="primary"
                      style={{ fontWeight: 'bolder' }}
                    />
                  ))}
              </Typography>
            </CardContent>

            <CardActions disableSpacing style={{ justifyContent: 'center' }}>
              <Tooltip
                title={(
                  <h3>
                    {canSeePreceptorships
                      ? 'Clique para ver as turmas!'
                      : 'Fora de horário! Recarregue esta página nas datas e horários acima.'}
                  </h3>
                )}
              >
                <IconButton style={{ background: '#fff' }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={!canSeePreceptorships}
                    onClick={() =>
                      history.push(
                        `${path}/registration/${registration._id}/student/${studentGroup}`,
                      )
                    }
                  >
                    Ver horários
                  </Button>
                </IconButton>
              </Tooltip>
            </CardActions>
          </Card>
        )}
      </div>

      {alert.isActive && (
        <Alert onClose={handleAlert} type={alert.type || 'success'}>
          {alert.message}
        </Alert>
      )}
    </Container>
  );
};

export default Admins;
