import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body {
    background: #f4f4f4;
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  button {
    cursor: pointer;
  }

.student-card, .admin-card, .group-card, .coordinator-card, .room-card, .registration-card {
  * {
    max-width: 100%;
  }
}
`;

// https://coolors.co/e63946-f1faee-a8dadc-457b9d-1d3557
// https://coolors.co/c5d5ea-759eb8-7392b7-b3c5d7-d8e1e9
// https://coolors.co/d64045-e9fff9-9ed8db-467599-1d3354
// https://coolors.co/0a369d-4472ca-5e7ce2-92b4f4-cfdee7
