import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
} from 'react';

import { useField } from '@unform/core';
import TextField from '@material-ui/core/TextField';

import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
}

const Input: React.FC<InputProps> = ({ name, label, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <TextField
        error={!!error}
        helperText={error || ''}
        inputProps={{defaultValue, ref: inputRef,  ...rest}}
        label={label}
      />
    </Container>
  );
};

export default Input;
