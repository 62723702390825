import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  .registration-card {
    display: flex;
    justify-content: center;

    @media (max-width: 650px) {
      width: 100%;
    }
  }

  .card-content {
    div {
      margin: 1%;
    }

    .schedules {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 10px;
    }
  }
`;

export const StyledLink = styled(Link)`
  color: #4472ca;
  display: flex;
  text-decoration: none;
  color: #000000;
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    media: {
      height: 0,
      paddingTop: '56.25%',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: '#4472CA',
      padding: 30,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);
