import React from 'react';

import {
  RouteProps as ReactDomRouterProps,
  Route as ReactDomRoute,
  Redirect,
} from 'react-router-dom';

import { useAuth } from '../hooks/Auth';

interface RouterProps extends ReactDomRouterProps {
  isPrivate?: boolean;
  component: React.FC<any>;
}

const Route: React.FC<RouterProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { token } = useAuth();

  return (
    <ReactDomRoute
      {...rest}
      render={({ location, ...rest }) => {
        return isPrivate === !!token ? (
          <Component {...rest} />
        ) : (
            <Redirect
              to={{
                pathname: isPrivate ? '/' : '/dashboard',
                state: { from: location },
              }}
            />
          );
      }}
    />
  );
};

export default Route;
