import styled from 'styled-components';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media (max-width: 1170px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .preceptorships-card {
    margin: 1%;
    width: 40%;

    @media (max-width: 1170px) {
      width: 100%;
    }
  }
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 1000,
    },
    media: {
      height: 0,
      paddingTop: '56.25%',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: '#4472CA',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);
