import React, { createContext, useCallback, useState, useContext } from 'react';

import api from '../services/api';

interface User {
  name: string;
  email: string;
  groups: string[];
}

interface AuthState {
  token: string;
  user: User;
}

interface LoginCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  token: string;
  user: User;
  login(credeitnals: LoginCredentials): Promise<void>;
  logout(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@PRECEPTORIA:token');
    const user = localStorage.getItem('@PRECEPTORIA:user');

    if (token && user) {
      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const login = useCallback(async ({ email, password }) => {
    const response = await api.post('/login', {
      email,
      password,
    });

    const { token, student } = response.data;

    const user = {
      name: `${student.firstName} ${student.lastName}`,
      email: student.email,
      groups: student.groups,
    };

    localStorage.setItem('@PRECEPTORIA:token', token);
    localStorage.setItem('@PRECEPTORIA:user', JSON.stringify(user));

    setData({ token, user });
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem('@PRECEPTORIA:token');
    localStorage.removeItem('@PRECEPTORIA:user');

    setData({} as AuthState);
  }, []);

  return (
    <AuthContext.Provider
      value={{ token: data.token, user: data.user, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { useAuth, AuthProvider };
