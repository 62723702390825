import React from 'react';
import { ButtonProps } from '@material-ui/core/Button';

import { Container } from './styles';

const button: React.FC<ButtonProps> = ({ children, ...rest }) => (
  <Container {...rest}>
    {children}
  </Container>
);

export default button;
