import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import socketio from 'socket.io-client';

import { Check, DesktopMac } from '@material-ui/icons';

import {
  Button,
  Typography,
  IconButton,
  CardActions,
  CardContent,
  CardHeader,
  Card,
  Tooltip,
  Backdrop,
  CircularProgress,
  Chip,
  Divider,
  Breadcrumbs,
  Link,
} from '@material-ui/core';

import { Container, useStyles } from './styles';

import api from '../../services/api';
import Alert from '../../components/Alert';

import { useAuth } from '../../hooks/Auth';
import { AlertProps } from '../../utils/dtos';

import { Preceptorships } from './dtos';

const socket = socketio.io(process.env.REACT_APP_PRECEPTORIA_API, {
  reconnection: true,
  reconnectionDelay: 2000,
  timeout: 60000,
});

interface PreceptorshipSocket {
  preceptorship: string;
  group: string;
}

interface ActionReducer {
  type: string;
  payload?: PreceptorshipSocket;
  init?: Preceptorships[];
}

interface Weekdays {
  [key: number]: string;
}

const weekDays: Weekdays = {
  1: 'Segunda',
  2: 'Terça',
  3: 'Quarta',
  4: 'Quinta',
  5: 'Sexta',
  6: 'Sábado',
  0: 'Domingo',
};

const PreceptorshipsList: React.FC = () => {
  const classes = useStyles();
  const { token } = useAuth();
  const { id, groupId } = useParams();

  const [preceptorships, setPreceptorships] = useState<Preceptorships[]>([]);
  const ref = useRef(preceptorships);
  const [signedIn, setSignedIn] = useState<Preceptorships | null>(null);

  const [alert, setAlert] = useState<AlertProps>({
    isActive: false,
  });

  const [loading, setLoading] = useState(true);

  const Authorization = `Bearer ${token}`;

  const handleVacancy = useCallback(
    (action: ActionReducer) => {
      const currentPreceptorships = ref.current;
      let findPrecepIdx = -1;
      let findGroupIdx = -1;

      if (action.payload) {
        findPrecepIdx = currentPreceptorships.findIndex(
          (precep) => precep._id === action.payload?.preceptorship,
        );

        findGroupIdx = currentPreceptorships[findPrecepIdx].vacancy.findIndex(
          (vacancy) => vacancy.group._id === action.payload?.group,
        );
      }

      switch (action.type) {
        case 'increment':
          if (action.payload && findPrecepIdx >= 0 && findGroupIdx >= 0) {
            currentPreceptorships[findPrecepIdx].vacancy[findGroupIdx]
              .placesLeft++;
          }

          currentPreceptorships[findPrecepIdx].students.pop();
          ref.current = currentPreceptorships;
          setPreceptorships([...currentPreceptorships]);
          break;
        case 'decrement':
          if (action.payload && findPrecepIdx >= 0 && findGroupIdx >= 0) {
            currentPreceptorships[findPrecepIdx].vacancy[findGroupIdx]
              .placesLeft--;
          }

          currentPreceptorships[findPrecepIdx].students.push({
            _id: 'fake id',
            date: new Date(),
            group: 'fake group',
            order: 1,
            student: 'fake student',
          });
          ref.current = currentPreceptorships;
          setPreceptorships([...currentPreceptorships]);
          break;
        case 'fetch':
          ref.current = action.init || [];
          setPreceptorships(action.init || []);
          break;
        default:
          break;
      }
    },
    [ref],
  );

  useEffect(() => {
    async function getPreceptorships() {
      try {
        const preceptorshipsResponse = await api.get(
          `/students/annualRegistration/${id}/preceptorships`,
          {
            headers: {
              Authorization,
            },
          },
        );

        const signedPreceptorshipsResponse = await api.get(
          `/students/annualRegistration/${id}/signedPreceptorships`,
          {
            headers: {
              Authorization,
            },
          },
        );

        if (signedPreceptorshipsResponse.data.length) {
          setSignedIn(signedPreceptorshipsResponse.data[0]);
        }

        handleVacancy({
          type: 'fetch',
          init: preceptorshipsResponse.data,
        });

        setAlert({
          isActive: true,
          type: 'success',
          message: 'Preceptorias listadas com sucesso.',
        });
      } catch (error) {
        setAlert({
          isActive: true,
          type: 'error',
          message: 'Erro ao listar preceptorias.',
        });
      } finally {
        setLoading(false);
      }
    }

    getPreceptorships();
  }, [handleVacancy]);

  useEffect(() => {
    socket.on('increment', (precepSocket: PreceptorshipSocket) => {
      handleVacancy({ type: 'increment', payload: precepSocket });
    });
  }, [handleVacancy]);

  useEffect(() => {
    socket.on('decrement', (precepSocket: PreceptorshipSocket) => {
      handleVacancy({ type: 'decrement', payload: precepSocket });
    });
  }, [handleVacancy]);

  const handleAlert = useCallback(() => {
    setAlert({ isActive: false });
  }, []);

  const handleStudentSign = useCallback(
    async (preceptorshipId) => {
      setLoading(true);

      try {
        // const signedPreceptorshipsResponse = await api.get(
        //   `/students/annualRegistration/${id}/signedPreceptorships`,
        //   {
        //     headers: {
        //       Authorization,
        //     },
        //   },
        // );

        // if (signedPreceptorshipsResponse.data.length) {
        //   const preceptorshipToUnsignId =
        //     signedPreceptorshipsResponse.data[0]._id;

        //   await api.delete(
        //     `/students/signPreceptorship/${preceptorshipToUnsignId}`,
        //     {
        //       headers: {
        //         Authorization,
        //       },
        //     },
        //   );
        // }

        const newSignedPreceptorship = await api.post(
          `/students/signPreceptorship`,
          {
            groupId,
            preceptorshipId,
            registrationId: id,
          },
          {
            headers: {
              Authorization,
            },
          },
        );

        setSignedIn(newSignedPreceptorship.data);

        setAlert({
          isActive: true,
          type: 'success',
          message: 'Incrição realizada com sucesso!',
        });
      } catch (error) {
        setAlert({
          isActive: true,
          type: 'error',
          message: 'Erro ao inscrever-se em uma preceptoria.',
        });
      } finally {
        setLoading(false);
      }
    },
    [id, groupId, Authorization],
  );

  const renderSubscribeButton = useCallback(
    (preceptorship: Preceptorships) => {
      const isSubscribed = signedIn?._id === preceptorship._id;
      const isFull =
        preceptorship.totalStudents === preceptorship.students.length;
      const isFilled =
        preceptorship.vacancy.find((v) => v.group._id === groupId)
          ?.placesLeft === 0;

      let label;

      if (isSubscribed) {
        label = 'Inscrito';
      } else if (isFilled || isFull) {
        label = 'Lotado';
      } else {
        label = 'Inscrever-se';
      }

      return (
        <>
          <Button
            variant="contained"
            color="secondary"
            disabled={isSubscribed || isFilled || isFull}
            onClick={() => handleStudentSign(preceptorship._id)}
          >
            {label}
          </Button>

          {isSubscribed && <Check color="secondary" />}
        </>
      );
    },
    [signedIn, groupId, handleStudentSign],
  );

  return (
    <Container>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/">
          Dashboard
        </Link>
        <Link color="inherit" href="/">
          Incrições anuais
        </Link>
        <Typography color="textPrimary">Turmas Preceptorias</Typography>
      </Breadcrumbs>

      <div className="cards-container">
        {!preceptorships.length && !loading && (
          <Card>
            <CardContent>
              <Typography color="textPrimary" component="p">
                Nenhuma turma cadastrada para esse ano.
              </Typography>
            </CardContent>
          </Card>
        )}

        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        {preceptorships?.map((preceptorship) => (
          <div className="preceptorships-card">
            <Card key={preceptorship._id} className={classes.root}>
              <CardHeader
                title={weekDays[moment(preceptorship.startTime).weekday()]}
                action={
                  <Chip
                    label={`${preceptorship.students.length} / ${preceptorship.totalStudents}`}
                    variant="outlined"
                    color="default"
                    style={{ margin: '2px', fontWeight: 'bolder' }}
                  />
                }
                subheader={`${moment(preceptorship.startTime).format(
                  'HH:mm',
                )} às ${moment(preceptorship.endTime).format('HH:mm')}`}
              />

              <Divider />

              <CardContent>
                <Typography
                  color="textSecondary"
                  component="p"
                  style={{ margin: '5px' }}
                >
                  Coordenadores
                </Typography>

                <Typography>
                  {preceptorship.coordinators.map((coord) => (
                    <Chip
                      label={coord.name}
                      variant="outlined"
                      color="secondary"
                      style={{ margin: '2px', fontWeight: 'bolder' }}
                    />
                  ))}
                </Typography>

                <Typography
                  color="textSecondary"
                  component="p"
                  style={{ margin: '5px', marginTop: '10px' }}
                >
                  Distribuição das Vagas
                </Typography>

                <Typography color="textSecondary" component="p">
                  {preceptorship.vacancy.map((v) => (
                    <Chip
                      label={`${v.group.name}: ${v.placesLeft}`}
                      variant="outlined"
                      color="primary"
                      style={{ margin: '2px', fontWeight: 'bolder' }}
                    />
                  ))}
                </Typography>
              </CardContent>

              <CardActions
                disableSpacing
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <IconButton style={{ background: '#fff' }}>
                  {renderSubscribeButton(preceptorship)}
                </IconButton>

                <div>
                  <Tooltip
                    title={
                      preceptorship.room.hasProjector
                        ? 'Tele-presencial'
                        : 'Presencial'
                    }
                  >
                    <Button>
                      <DesktopMac
                        color={
                          preceptorship.room.hasProjector
                            ? 'primary'
                            : 'disabled'
                        }
                        style={{ margin: '2px' }}
                      />
                      <span>{preceptorship.room.name}</span>
                    </Button>
                  </Tooltip>
                </div>
              </CardActions>
            </Card>
          </div>
        ))}
      </div>

      {alert.isActive && (
        <Alert onClose={handleAlert} type={alert.type || 'success'}>
          {alert.message}
        </Alert>
      )}
    </Container>
  );
};

export default PreceptorshipsList;
