import styled from 'styled-components';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Form } from '@unform/web';

export const Container = styled.div``;

export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;

  .row {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 1%;
    padding: 1%;
  }
`;

export const FormActions = styled.div`
  display: flex;
  justify-content: center;

  * {
    margin: 2%;
  }
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      minHeight: 'fit-content',
      maxHeight: 'fit-content',
    },
    content: {
      width: '100%',
    },
    actions: {
      margin: '20px',
    },
  }),
);
