import React, { useRef, useCallback, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormHandles } from '@unform/core';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { useAuth } from '../../hooks/Auth';
import Logo from '../../assets/Ceapia-01.png';

import Input from '../../components/Input';
import Button from '../../components/Button';
import Alert from '../../components/Alert';

import getValidationErrors from '../../utils/getValidationErrors';

import {
  Container,
  Content,
  FormContainer,
  FormContent,
  AlertContainer,
} from './styles';

interface LoginFormData {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);

  const { login } = useAuth();
  const history = useHistory();

  const handleAlertClose = useCallback(() => {
    setAlert(false);
  }, []);

  const handleSubmit = useCallback(async (data: LoginFormData) => {
    setLoading(true);

    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        email: Yup.string()
          .email('Digite um e-mail válido.')
          .required('E-mail obrigatório.'),
        password: Yup.string().required('Senha obrigatória.'),
      });

      await schema.validate(data, { abortEarly: false });

      await login({
        email: data.email,
        password: data.password,
      });

      history.push('/dashboard');
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);

        formRef.current?.setErrors(errors);
      } else {
        return setAlert(true);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <Container>
      <Content>
        <FormContainer>
          <img src={Logo} alt="Logo" />

          <h2>PRECEPTORIA</h2>

          <FormContent ref={formRef} onSubmit={handleSubmit}>
            <Input
              name="email"
              type="email"
              label="E-mail"
              placeholder="Digite seu e-mail"
            />

            <Input
              name="password"
              type="password"
              label="Senha"
              placeholder="Digite sua senha"
            />

            <Button type="submit" color="primary" variant="contained">
              {loading ? (
                <CircularProgress color="inherit" size={15} />
              ) : (
                'Entrar'
              )}
            </Button>
          </FormContent>
        </FormContainer>

        {alert && (
          <AlertContainer>
            <Alert onClose={handleAlertClose} type="error">
              Credenciais inválidas.
            </Alert>
          </AlertContainer>
        )}
      </Content>
    </Container>
  );
};

export default Login;
