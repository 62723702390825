import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 2%;
  justify-content: center;
`;

export const Content = styled.div`
  min-width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FormContainer = styled.div`
  align-self: center;

  h2,
  h3 {
    text-align: center;
    font-weight: normal;
  }
`;

export const FormContent = styled(Form)`
  * {
    width: 100%;
    padding: 2% 0;
  }
`;

export const AlertContainer = styled.div`
  width: 100%;
`;
