import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import Routes from './routes';
import Providers from './hooks';

import GlobalStyle from './styles/global';

function App() {
  return (
    <>
      <BrowserRouter>
        <Providers>
          <Routes />
        </Providers>

        <GlobalStyle />
      </BrowserRouter>
    </>
  );
}

export default App;
