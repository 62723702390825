import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

interface AlertComponentProps {
  type: 'success' | 'info' | 'warning' | 'error';
  onClose?(): void;
}

const Alert: React.FC<AlertComponentProps> = ({ type, onClose, ...rest }) => {
  const [open, setOpen] = React.useState(true);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);

    if (onClose) onClose();
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <MuiAlert
        onClose={handleClose}
        elevation={6}
        variant="filled"
        severity={type}
        {...rest}
      />
    </Snackbar>
  );
};

export default Alert;
